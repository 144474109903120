import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import useAuth from '../_hooks/useAuth';
import useUser from '../_hooks/useUser';

const Access = ({ yes, no, ulevel, perform }) => {
	const { authenticated } = useAuth();
	const { userData, checkAccess } = useUser();

	const [isAccess, setIsAccess] = useState(false);
	useEffect(() => setIsAccess(checkAccess(ulevel)), [authenticated, userData]);

	return isAccess ? yes() : no();
};

Access.defaultProps = {
	ulevel: null, // ex. 4000
	perform: null // ex. posts:delete
};

Access.propTypes = {
	yes: PropTypes.func.isRequired,
	no: PropTypes.func.isRequired,
	ulevel: PropTypes.string,
	perform: PropTypes.string
};

export default Access;
