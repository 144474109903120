import React from 'react';
import PropTypes from 'prop-types';

import styles from '../search-filters.module.scss';
import Select from '../../form/select';
import useLanguage from '../../_hooks/useLanguage';
import useFilter from '../../_hooks/useFilter';

const Desktop = ({ variation, isFilters, setSelectMultipleSelected }) => {
	const { t } = useLanguage();
	const { updateFilter, getFilterNodeValue, coderegisters } = useFilter();

	return (
		<div className={`${styles.dropdowns} ${variation === 'hero' ? 'd-flex' : 'd-none d-lg-flex'}`}>
			<div className={styles.item}>
				<Select variation="reactSelect" name="type_id" onChange={updateFilter} list={coderegisters.aa_real_estate_type} selected={getFilterNodeValue('type_id')} reset={isFilters ? null : { id: null, text: t('placeholderSelectAllTypes') }} placeholder={t('labelPropertyType')} />
			</div>
			{isFilters && coderegisters.aa_real_estate_subtype.length > 0 && (
				<div className={styles.item}>
					<Select variation="multiple" propsByType={{ disableSearch: true, selectAll: false }} name="subtype_id" onChange={updateFilter} list={coderegisters.aa_real_estate_subtype} selected={setSelectMultipleSelected('subtype_id')} placeholder={t('labelPropertySubtype')} />
				</div>
			)}
			<div className={styles.item}>
				<Select variation="reactSelect" name="geo_location_level1_id" onChange={updateFilter} list={coderegisters.aa_geo_location_level1} selected={getFilterNodeValue('geo_location_level1_id')} reset={isFilters ? null : { id: null, text: t('placeholderSelectAllRegion') }} placeholder={t('labelRegion')} />
			</div>
			{coderegisters.aa_geo_location_level3.length > 0 && (
				<div className={styles.item}>
					<Select variation="reactSelect" name="geo_location_level3_id" onChange={updateFilter} list={coderegisters.aa_geo_location_level3} selected={getFilterNodeValue('geo_location_level3_id')} placeholder={t('labelCity')} />
				</div>
			)}
		</div>
	);
};

Desktop.defaultProps = {
	variation: 'default',
	isFilters: false
};

Desktop.propTypes = {
	variation: PropTypes.string,
	isFilters: PropTypes.bool,
	setSelectMultipleSelected: PropTypes.func.isRequired
};

export default Desktop;
