import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

import styles from './search-filters.module.scss';
import InputRangeSlider from '../form/input-range-slider';
import Checkbox from '../form/checkbox';
import Tag from '../tag/tag';
import Access from '../auth/access';
import useLanguage from '../_hooks/useLanguage';
import useFilter from '../_hooks/useFilter';
import useUser from '../_hooks/useUser';
import { useStore as filterStore } from '../../contexts/filter';

const Filters = ({ locked }) => {
	const { t } = useLanguage();
	const { updateFilter, resetFilter, isFilterActive, cntByGroup } = useFilter();
	const { isMember, showCallout, paidAccess } = useUser();

	// Handle user ulevel
	const { data, dispatch } = filterStore();
	const { filter, cnt, fieldsProps } = data;

	const [open, setOpen] = useState(false);
	const [filtersCnt, setFiltersCnt] = useState(0);

	const onChange = (e, fId) => {
		if (isMember) {
			setFiltersCnt(filtersCnt + 1 * (isFilterActive(e) ? 1 : -1));
			updateFilter(e);
		} else {
			showCallout();
		}
	};

	const clearFilters = () => {
		resetFilter();
		setFiltersCnt(0);
	};

	useEffect(() => {
		setFiltersCnt(cntByGroup('filter'));
	}, [filter]);

	return (
		<>
			{open && <button type="button" className={styles.filtersCloser} onClick={() => setOpen(!open)} aria-label="Zapri filtre" />}
			<div className={`${styles.filters} ${open ? styles.open : ''}`}>
				<div className={`d-none d-lg-flex ${styles.filtersActions}`}>
					<button type="button" className={`btn outline default i ${filtersCnt > 0 ? 'active' : ''}`} onClick={() => setOpen(!open)}><i className="icon filters light" />{t('btnFilters')} {filtersCnt > 0 && <Tag classes="rounded dark">{filtersCnt}</Tag>}{!isMember && <Tag classes="primary">PRO</Tag>}</button>
					{cnt > 0 && <button type="button" className="btn outline primary semibold i" onClick={() => paidAccess(() => dispatch({ type: 'SUBMIT_FILTER_SAVE', payload: { submit: true } }))} aria-label="Shrani filtre"><i className="icon bell primary" /> {t('btnSaveFilters')}</button>}
				</div>
				{locked && <div className={styles.locked} />}

				<div className={styles.box}>
					<div className={styles.sectionWrapper}>
						<section>
							<Checkbox name="auction_share_sale_all" value="1" variation="toggle" onChange={e => onChange(e, 'auction_share_sale_all')} checked={filter.auction_share_sale_all && filter.auction_share_sale_all.value === '1'}>
								<div className={styles.label}>
									<h4>{t('filterSectionTitle2')}</h4>
									<p>{t('filterToggleTextOpt2')}</p>
								</div>
							</Checkbox>
						</section>

						<section>
							<h4>{t('filterSectionTitle3')}</h4>

							<Checkbox name="auction_sequence[]" value="1" onChange={e => onChange(e, 'auction_sequence_1')} checked={filter.auction_sequence && filter.auction_sequence.value.includes('1')}>
								<div className={styles.label}>
									<p>{t('filterToggleTitleOpt3')}</p>
									<p>{t('filterToggleTextOpt3')}</p>
								</div>
							</Checkbox>

							<Checkbox name="auction_sequence[]" value="2" onChange={e => onChange(e, 'auction_sequence_2')} checked={filter.auction_sequence && filter.auction_sequence.value.includes('2')}>
								<div className={styles.label}>
									<p>{t('filterToggleTitleOpt4')}</p>
									<p>{t('filterToggleTextOpt4')}</p>
								</div>
							</Checkbox>

							<Checkbox name="auction_offer_type[]" value="4" onChange={e => onChange(e, 'auction_offer_type_4')} checked={filter.auction_offer_type && filter.auction_offer_type.value.includes('4')}>
								<div className={styles.label}>
									<p>{t('filterToggleTitleOpt5')}</p>
									<p>{t('filterToggleTextOpt5')}</p>
								</div>
							</Checkbox>

							<Checkbox name="auction_offer_type[]" value="5" onChange={e => onChange(e, 'auction_offer_type_5')} checked={filter.auction_offer_type && filter.auction_offer_type.value.includes('5')}>
								<div className={styles.label}>
									<p>{t('filterToggleTitleOpt6')}</p>
									<p>{t('filterToggleTextOpt6')}</p>
								</div>
							</Checkbox>
						</section>

						<section>
							<h4>{t('filterSectionTitle4')}</h4>
							<InputRangeSlider key={fieldsProps.price.resetCnt} range name="price" min={0} max={500000} x={null} y={null} step={1000} inputSize="sm" inputSuffix="€" onChange={e => onChange(e, 'price')} inputPlaceholderFrom={t('label_from')} inputPlaceholderTo={t('label_to')} />
						</section>

						<section className={styles.borderBottom}>
							<Checkbox name="auction_expire_soon" value="1" variation="toggle" onChange={e => onChange(e, 'auction_expire_soon')} checked={filter.auction_expire_soon && filter.auction_expire_soon.value === '1'}>
								<div className={styles.label}>
									<h4>{t('filterSectionTitle1')}</h4>
									<p>{t('filterToggleTextOpt1')}</p>
								</div>
							</Checkbox>
						</section>
					</div>

					<section className={`d-none d-md-flex ${styles.buttons} ${!isMember ? styles.alignRight : ''}`}>
						<Access
							ulevel="4000"
							yes={() => (
								<>
									<button type="button" className="btn plain light-text" onClick={() => clearFilters()} aria-label="Počisti filtre">{t('btnClean')}</button>
									<button type="submit" className="btn dark" onClick={() => setOpen(!open)} aria-label="Zapri filtre">{t('btnClose')}</button>
								</>
							)}
							no={() => <button type="button" className="btn dark" onClick={() => showCallout()}>{t('btnBecomePro')}</button>}
						/>
					</section>
				</div>
			</div>
		</>
	);
};

Filters.propTypes = {
	locked: PropTypes.bool.isRequired
};

export default Filters;
