import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import styles from '../search-filters.module.scss';
import Checkbox from '../../form/checkbox';
import Radiobutton from '../../form/radiobutton';
import useLanguage from '../../_hooks/useLanguage';
import useFilter from '../../_hooks/useFilter';
import useUser from '../../_hooks/useUser';
import { useStore as appStore } from '../../../contexts/app';
import Filters from '../filters';
import Tag from '../../tag/tag';

const Mobile = ({ variation, isFilters, locked }) => {
	const { t } = useLanguage();
	const { data, dispatch, coderegisters, isFilterActive, updateFilter, getFilterNodeValue, getFilterNodeText, getFilterAPIReady } = useFilter();
	const { filter, open, lastFilter } = data;

	const { dispatch: appDispatch } = appStore();

	// Handle user ulevel
	
	const { isMember } = useUser();

	// Toggle

	const [toggle, setToggle] = useState({
		type_id: true,
		subtype_id: false,
		geo_location_level1_id: true,
		geo_location_level3_id: false,
		filters: true
	});

	const toggler = (name) => {
		setToggle({ ...toggle, [name]: !toggle[name] });
	};

	// Selected filter

	const [selected, setSelected] = useState({});

	useEffect(() => {
		setSelected(getFilterAPIReady(filter));
	}, [filter]);

	// Auto open linked filter section (ex. type => subtype)

	useEffect(() => {
		if (lastFilter.name === 'type_id') setToggle({ ...toggle, subtype_id: true });
		if (lastFilter.name === 'geo_location_level1_id') setToggle({ ...toggle, geo_location_level3_id: true });
	}, [lastFilter]);

	useEffect(() => {
		appDispatch({ type: 'SET_BODY_SCROLL_OFF', payload: open });
	}, [open]);

	return (
		<div className={`${styles.dropdowns} d-${open ? 'flex' : 'none'} d-lg-none ${styles.mobile}`}>
			<div className={styles.top}>
				<button type="button" onClick={() => { dispatch({ type: 'TOGGLE' }); }}><i className="icon times light" /></button>
				<h2>{t('labelFilters')}</h2>
			</div>
			<div className={styles.body}>
				<div className={styles.item}>
					<button type="button" onClick={() => toggler('type_id')} className={toggle.type_id ? styles.active : ''}><h4>{t('labelPropertyTypeLong')}</h4></button>
					<p>{selected.type_id && getFilterNodeText('type_id', selected.type_id)}</p>
					<div className={styles.list}>
						<Radiobutton list={coderegisters.aa_real_estate_type} defaultValue={getFilterNodeValue('type_id')} variation="dark" name="type_id" onChange={updateFilter} />
					</div>
				</div>

				{coderegisters.aa_real_estate_subtype.length > 0 && (
					<div className={styles.item}>
						<button type="button" onClick={() => toggler('subtype_id')} className={toggle.subtype_id ? styles.active : ''}><h4>{t('labelPropertySubtypeLong')}</h4></button>
						<p>{selected.subtype_id && selected.subtype_id.map(id => getFilterNodeText('subtype_id', id)).join(', ')}</p>
						<div className={styles.list}>
							{coderegisters.aa_real_estate_subtype.map((row) => {
								return <Checkbox key={row.id} name="subtype_id[]" uniqueid={`-m-${row.id}`} value={row.id} checked={filter.subtype_id && filter.subtype_id.value.includes(row.id)} onChange={updateFilter}>{row.text}</Checkbox>;
							})}
						</div>
					</div>
				)}

				<div className={styles.item}>
					<button type="button" onClick={() => toggler('geo_location_level1_id')} className={toggle.geo_location_level1_id ? styles.active : ''}><h4>{t('labelRegion')}</h4></button>
					<p>{selected.geo_location_level1_id && getFilterNodeText('geo_location_level1_id', selected.geo_location_level1_id)}</p>
					<div className={styles.list}>
						<Radiobutton list={coderegisters.aa_geo_location_level1} defaultValue={getFilterNodeValue('geo_location_level1_id')} variation="dark" name="geo_location_level1_id" onChange={updateFilter} />
					</div>
				</div>

				{coderegisters.aa_geo_location_level3.length > 0 && (
					<div className={styles.item}>
						<button type="button" onClick={() => toggler('geo_location_level3_id')} className={toggle.geo_location_level3_id ? styles.active : ''}><h4>{t('labelCity')}</h4></button>
						<p>{selected.geo_location_level3_id && getFilterNodeText('geo_location_level3_id', selected.geo_location_level3_id)}</p>
						<div className={styles.list}>
							<Radiobutton list={coderegisters.aa_geo_location_level3} defaultValue={getFilterNodeValue('geo_location_level3_id')} variation="dark" name="geo_location_level3_id" onChange={updateFilter} />
						</div>
					</div>
				)}

				<div className={styles.item}>
					<button type="button" onClick={() => toggler('filters')} className={toggle.filters ? styles.active : ''}><h4>{t('labelFilters')} {!isMember && <Tag classes="primary">PRO</Tag>}</h4></button>
					<p />
					<div className={styles.list}>
						<Filters onChange={updateFilter} isFilterActive={isFilterActive} locked={locked} />
					</div>
				</div>
			</div>
			<div className={styles.bottom}>
				<button type="button" className="btn plain" onClick={() => { dispatch({ type: 'RESET_FILTER' }); }}>{t('btnClean')}</button>
				<button type="button" className="btn dark" onClick={() => { dispatch({ type: 'TOGGLE' }); }}>{t('btnShowItems')}</button>
			</div>
		</div>
	);
};

Mobile.defaultProps = {
	variation: 'default',
	isFilters: false
};

Mobile.propTypes = {
	variation: PropTypes.string,
	isFilters: PropTypes.bool,
	locked: PropTypes.bool.isRequired
};

export default Mobile;
