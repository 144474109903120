import React, { useState, useEffect, useRef } from 'react';
import { Range } from 'rc-slider';
import PropTypes from 'prop-types';

import { formatPrice, replaceAll } from '../../helpers/number';

const InputSlider = ({ name, children, inputSize, inputSuffix, inputPlaceholderFrom, inputPlaceholderTo, min, max, x, y, step, onChange, range, disabled }) => {
	const [inputX, setInputX] = useState(x);
	const [inputY, setInputY] = useState(y);
	const [isChanged, setIsChanged] = useState(false);

	// Throttling
	const throttleTimeout = useRef(null);

	const setValue = (e) => {
		if (e.cancelable) {
			e.preventDefault();
		}

		if (isChanged === false) setIsChanged(true);

		setInputX(e[0]);
		setInputY(e[1]);
	};

	const handleOnChange = () => {
		onChange({
			target: {
				type: 'input-range-slider',
				name,
				value: [inputX, inputY].join('-'),
				valuePrint: [
					inputSuffix ? `${formatPrice(inputX)} ${inputSuffix}` : formatPrice(inputX),
					inputSuffix ? `${formatPrice(inputY)} ${inputSuffix}` : formatPrice(inputY)
				].join(' - ')
			}
		});
	};

	useEffect(() => {
		if (inputX || inputY) {
			if (throttleTimeout.current) clearTimeout(throttleTimeout.current);
			throttleTimeout.current = setTimeout(() => { handleOnChange(); }, 500);
		}
	}, [inputX, inputY]);

	return (
		<div className="sliderInput">
			{!range && (
				<header>
					{children && <span className="label">{children}</span>}
					<label htmlFor={name} className="input-label">
						<input type="text" name={name} value={formatPrice(inputX)} onChange={e => setInputX(e.target.value)} className={inputSize} />
						{inputSuffix && <span>{inputSuffix}</span>}
					</label>
				</header>
			)}
			<div className="slider">
				<Range
					defaultValue={[x || 10000, y || 100000]}
					value={[inputX || 10000, inputY || 100000]}
					min={min}
					max={max}
					step={step}
					onChange={setValue}
					disabled={disabled}
				/>
			</div>
			{range && (
				<div className="rangeInputs">
					<input type="text" name={name} value={inputX ? formatPrice(inputX) : ''} onChange={e => setInputX(replaceAll('.', '', e.target.value))} className={`border ${inputSize}`} disabled={disabled} placeholder={inputPlaceholderFrom} />
					<span />
					<input type="text" name={name} value={inputY ? formatPrice(inputY) : ''} onChange={e => setInputY(replaceAll('.', '', e.target.value))} className={`border ${inputSize}`} disabled={disabled} placeholder={inputPlaceholderFrom} />
				</div>
			)}
		</div>
	);
};

InputSlider.defaultProps = {
	children: null,
	inputSize: 'md',
	inputSuffix: '',
	inputPlaceholderFrom: null,
	inputPlaceholderTo: null,
	min: 0,
	max: 100,
	x: 10,
	y: 100,
	step: 1,
	range: false,
	disabled: false
};

InputSlider.propTypes = {
	name: PropTypes.string.isRequired,
	children: PropTypes.node,
	inputSize: PropTypes.string,
	inputSuffix: PropTypes.string,
	inputPlaceholderFrom: PropTypes.string,
	inputPlaceholderTo: PropTypes.string,
	min: PropTypes.number,
	max: PropTypes.number,
	x: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string
	]),
	y: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string
	]),
	step: PropTypes.number,
	onChange: PropTypes.func.isRequired,
	range: PropTypes.bool,
	disabled: PropTypes.bool
};

export default InputSlider;
