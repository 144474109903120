export const replaceAll = (find, replace, str) => {
	const Regex = (find === '.') ? /\./g : new RegExp(find, 'g');
	return str.replace(Regex, replace);
};

export const formatPrice = (v) => {
	if (v !== undefined && v && v !== '0' && v !== '') {
		let value = v.toString();

		// Set string, replace separator
		if (value.indexOf('.') !== -1) {
			value = replaceAll('.', '', value);
		}

		// Format and update in element
		const Formatted = numberFormat(value, 0, '.', '.');
		return Formatted;
	}

	return 0;
};

export const toFixedFix = (n, p) => {
	const k = 10 ** p;
	return (Math.round(n * k) / k).toFixed(p);
};

export const numberFormat = (number, decimals, decPoint, thousandsSep) => {
	const no = number.replace(/[^0-9+\-Ee.]/g, '');

	const num = !isFinite(+no) ? 0 : +no;
	const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
	const sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep;
	const dec = (typeof decPoint === 'undefined') ? '.' : decPoint;

	// Fix for IE parseFloat(0.55).toFixed(0) = 0;
	const x = prec ? toFixedFix(num, prec) : Math.round(num);
	const s = x.toString().split('.');

	if (s[0].length > 3) {
		s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
	}

	if ((s[1] || '').length < prec) {
		s[1] = s[1] || '';
		s[1] += new Array(prec - s[1].length + 1).join('0');
	}

	return s.join(dec);
};
