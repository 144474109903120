import React, { useEffect } from 'react';
import Router, { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import styles from './search-filters.module.scss';
import Filters from './filters';
import Desktop from './device/desktop';
import Mobile from './device/mobile';
import TagList from './tag/list';
import useLanguage from '../_hooks/useLanguage';
import useFilter from '../_hooks/useFilter';
import useHistory from '../_hooks/useHistory';

const SearchFilters = ({ isFilters, variation, isTags, locked }) => {
	const { query } = useRouter();
	const { isPrevMatch } = useHistory();

	const { t } = useLanguage();
	const { coderegisters, data, dispatch, setFilter, updateFilter, saveFilter, getFilterAPIReady, resetFilter, clearAll } = useFilter();
	const { filter, cnt } = data;

	// Filters handle

	const setSelectMultipleSelected = (name) => {
		if (filter[name] && filter[name].value.length > 0) {
			return filter[name].value.map((id) => {
				const search = coderegisters.aa_real_estate_subtype.filter(row => row.id === id);

				return {
					id,
					text: search.length ? search[0].text : 'n/a?'
				};
			});
		}

		return [];
	};

	const setRemoteFilter = () => {
		Router.push('/drazbe?remotef=true');
	};

	// Run query on filters, set FORM selection
	useEffect(() => {
		// Set correct count of applyed filters
		const filtersReady = getFilterAPIReady();
		let c = 0;
		Object.keys(filtersReady).map((k) => {
			if (filtersReady[k]) {
				if (typeof filtersReady[k] === 'object') c += filtersReady[k].length;
				else c += 1;
			}

			return true;
		});

		dispatch({
			type: 'UPDATE_CNT',
			payload: { cnt: c }
		});
	}, [filter]);

	// Save filters
	const onFilterSave = (d, e) => {
		Router.push('/profil/shranjeno/filtri');
	};

	useEffect(() => {
		if (data.submit === true) {
			saveFilter(onFilterSave);

			dispatch({
				type: 'SUBMIT_FILTER_SAVE',
				payload: { submit: false }
			});
		}
	}, [data.submit]);

	// Reset or no saved filters (in session)
	useEffect(() => {
		/*
		Reset if:
			- user is not returned back to list from detail page (history prev contain /drazba/)
			- use is not came to list from home hero search (query contain remotef)
		*/
		if (!isPrevMatch('/drazba/') && !query.remotef) { // ex. mojedrzabe.com/drazba/prodaja-hisa-samostojna-ljubljana-okolica-domzale-37
			clearAll();
		}
	}, []);

	// Component options

	const deviceProps = {
		variation,
		updateFilter
	};

	return (
		<div className={styles[variation]}>
			<div className={`${styles.items}`}>
				{locked && <span className={styles.locked} />}
				<Desktop {...deviceProps} isFilters={isFilters} setSelectMultipleSelected={setSelectMultipleSelected} />
				{variation !== 'hero' && <Mobile {...deviceProps} resetFilter={resetFilter} locked={locked} />}

				{isFilters && <Filters locked={locked} />}
				{!isFilters && <button type="button" className="btn primary i" onClick={() => setRemoteFilter()} aria-label="Odpri"><i className="icon search" /> {t('btnShow')}</button>}
			</div>

			{isTags && cnt > 0 && (
				<div className={`d-none d-lg-flex ${styles.tags}`}>
					<TagList />
					{cnt > 0 && <button type="button" onClick={() => setFilter({})} className={styles.clearAll} aria-label="Odstrani tage">{t('btnCleanAll')}</button>}
				</div>
			)}
		</div>
	);
};

SearchFilters.defaultProps = {
	isFilters: false,
	variation: 'default',
	isTags: true,
	locked: false
};

SearchFilters.propTypes = {
	variation: PropTypes.string,
	isFilters: PropTypes.bool,
	isTags: PropTypes.bool,
	locked: PropTypes.bool
};

export default SearchFilters;
