import React from 'react';
import PropTypes from 'prop-types';

import useFilter from '../../_hooks/useFilter';
import Tag from '../../tag/tag';

const FilterTag = ({ name, nameHtml, value, valuePrint, readonly, coderegisters }) => {
	const { getFilterNodeText, updateFilter } = useFilter();

	return (
		<Tag key={name} classes="filter">
			{valuePrint || getFilterNodeText(name, value, coderegisters)}
			{readonly === false && <button type="button" className="btn reset" onClick={() => { updateFilter({ target: { type: 'delete', name: nameHtml || name, value } }); }}><i className="icon times sm" /></button>}
		</Tag>
	);
};

FilterTag.defaultProps = {
	nameHtml: null,
	value: null,
	valuePrint: null,
	readonly: false,
	coderegisters: null
};

FilterTag.propTypes = {
	name: PropTypes.string.isRequired,
	nameHtml: PropTypes.string,
	value: PropTypes.string,
	valuePrint: PropTypes.string,
	readonly: PropTypes.bool,
	coderegisters: PropTypes.shape({})
};

export default FilterTag;
