import React, { useState } from 'react';
import PropTypes from 'prop-types';

import FilterTag from './tag';
import useFilter from '../../_hooks/useFilter';

const TagList = ({ filter, readonly, maxVisible }) => {
	const [visibleCnt, setVisibleCnt] = useState(maxVisible);
	const { data, getFilterAPIReady, setFilterTags, setCoderegistersList } = useFilter();

	const filterObject = filter || getFilterAPIReady(data.filter); // data.filter = from global filter stare (active search filter) - normalized to same object structure, as get from API>items = mutate object structure
	const f = setFilterTags(filterObject);

	// Set coderegisters list, depend on current filter (prepare correct linked subtables, depend on its "parent" ... ex: type_id > subtype_id)
	const coderegisters = setCoderegistersList(filterObject.type_id, filterObject.geo_location_level1_id);

	// Count, how many tags is visible (if max)
	let cnt = 0;

	const isTagVisible = () => {
		const is = (visibleCnt === null || (visibleCnt !== null && visibleCnt >= cnt));
		if (is === true) cnt += 1;

		return is;
	};

	return (
		<div>
			{f.map((row, index) => {
				const { name, value, valuePrint } = row;

				if (isTagVisible() === true) {
					return Array.isArray(value)
						? value.map((v) => {
							if (isTagVisible() === true) {
								return <FilterTag key={`${name}${v}`} name={name} value={v} nameHtml={`${name}[]`} readonly={readonly} coderegisters={coderegisters} />;
							}

							return true;
						})
						: <FilterTag key={`${name}${value}`} name={name} value={value} valuePrint={valuePrint} readonly={readonly} coderegisters={coderegisters} />;
				}

				return true;
			})}

			{isTagVisible() === false && <button type="button" onClick={() => setVisibleCnt(null)}>...</button>}
		</div>
	);
};

TagList.defaultProps = {
	filter: null,
	readonly: false,
	maxVisible: null
};

TagList.propTypes = {
	filter: PropTypes.shape({}),
	readonly: PropTypes.bool,
	maxVisible: PropTypes.number
};

export default TagList;
